import React, { useState } from 'react';

const ForgotPassword = ({ alert },props) => { // Accept the alert function as a prop
  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(props.server+'/api/auth/forgotpassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (data.success) {
        alert(data.message, 'success'); // Use the passed alert function
      } else {
        alert(data.error || 'Forgot password failed.', 'danger'); // Use the passed alert function
      }
    } catch (error) {
      console.error('Error:', error.message);
      alert('An error occurred. Please try again later.', 'danger'); // Use the passed alert function
    }
  };

  return (
    <div style={{ backgroundImage: `url(${require('../images/img.jpg')})`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <form style={{ width: '400px', padding: '60px', backgroundColor: 'black', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }} onSubmit={handleSubmit}>
        <h2 style={{ color: 'orangered', textAlign: 'center' }}>Forgot Password</h2>
        <label style={{ display: 'block', marginBottom: '8px', color: 'white' }} htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={handleChange}
          style={{ width: '100%', padding: '10px', marginBottom: '20px', boxSizing: 'border-box', backgroundColor: 'black', color: 'white', border: '1px solid rgb(220, 220, 220)', borderRadius: '5px', outline: 'none' }}
          required
        />
        <button type="submit" style={{ width: '100%', padding: '12px', backgroundColor: 'orangered', color: 'black', border: 'none', borderRadius: '5px', cursor: 'pointer', transition: 'background-color 0.3s ease' }}>Reset Password</button>
      </form>
    </div>
  );
};

export default ForgotPassword;
