// InfoCard.js

import React, { useState, useEffect } from 'react';
import { ScaleLoader } from 'react-spinners';
import '../../styles/InfoCard.css';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function InfoCard({ anime }) {
  const [animeData, setAnimeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await fetch(`https://anime-api-t143.onrender.com/anime/info?id=${anime.id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setAnimeData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [anime.id]);

  if (loading) {
    return (
      <div className="info-card text-center">
        <ScaleLoader color="orangered" css={override} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Truncate description to 20 words
  const truncatedDescription = animeData.anime.info.description.split(' ').slice(0, 20).join(' ');

  return (
    <div className="info-card d-flex mx-2">
      <Link to={`/info?id=${anime.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <h5>{animeData.anime.info.name}</h5>
        <p>{truncatedDescription}...</p>
        <div className="stats-container" style={{ borderRadius: '50px', display: 'flex', width: '300px',  justifyContent: 'space-between', }}>
          <p style={{ background: 'lightblue', padding: '8px', flex: 1 }}>Rating: {animeData.anime.info.stats.rating}</p>
          <p style={{ background: 'lightcoral', padding: '8px', flex: 1 }}>Sub: {animeData.anime.info.stats.episodes.sub}</p>
          <p style={{ background: 'lightsalmon', padding: '8px', flex: 1 }}>Dub: {animeData.anime.info.stats.episodes.dub}</p>
          <p style={{ background: 'lightcyan', padding: '8px', flex: 1 }}>Type: {animeData.anime.info.stats.type}</p>
          <p style={{ background: 'lightgoldenrodyellow', padding: '8px', flex: 1 }}>Duration: {animeData.anime.info.stats.duration}</p>
        </div>
        <p>Status: {animeData.anime.moreInfo.status}</p>
      </Link>
    </div>
  );
}

export default InfoCard;
