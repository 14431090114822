import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/Search.css';
import { Link } from 'react-router-dom';

const SearchInput = ({ closeSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const history = useNavigate();

  const handleChange = async (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    await handleSearch(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    closeSearch();
    // Perform any additional logic before navigating, if needed
    history(`/result?search=${searchTerm}`);
  };

  const handleSearch = async (term) => {
    try {
      const response = await fetch(`https://anime-api-t143.onrender.com/anime/search/suggest?q=${term}`);
      const data = await response.json();
      console.log('API Response:', data);
      setSearchResults(data.suggestions ?? []);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  return (
    <div className="search-options">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Search..."
          className="search-input"
          value={searchTerm}
          onChange={handleChange}
        />
      </form>
      <button className="filter-button">Filter</button>

      <div className="search-results">
        {searchResults.map((result) => (
          <Link to={`/info?id=${result.id}`} key={result.id} onClick={closeSearch}>
            <div className="result-item">
              <img src={result.poster} alt={result.name} />
              <div className="details">
                <p>{result.name}</p>
                <p>{result.moreInfo.join(' | ')}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SearchInput;
