import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AnimeCard from '../Common/AnimeCard';
import '../../styles/Result.css'
import { css } from '@emotion/react';
import { ScaleLoader } from 'react-spinners'


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const Result = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('search') || '';
  const [page, setPage] = useState(1);
  const [animeList, setAnimeList] = useState([]);
  const [popanimeList, setPopAnimeList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true); 

  const fetchAnimeData = async (query, page) => {
    setLoading(true);
    try {
      const response = await fetch(`https://anime-api-t143.onrender.com/anime/search?q=${query}&page=${page}`);
      const data = await response.json();
      setAnimeList(data.animes || []);
      setPopAnimeList(data.mostPopularAnimes || []);
      setCurrentPage(data.currentPage || 1);
      setTotalPages(data.totalPages || 1);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoading(false);  // Set loading to false when data is fetched
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchAnimeData(query, page);
  }, [query, page]);

  if (loading) {
    return (
      <div className="spinner-container">
        <ScaleLoader color={'orangered'} css={override} size={150} />
      </div>
    );
  }

  return (
    <div>

      <div style={{ margin: '20px', marginBottom: '20px', background: 'rgba(200, 200, 200, 0.3)', borderRadius: '50px', padding: '10px' }}>
        <h1 style={{ color: 'orangered', textAlign: 'center', fontFamily: 'Arial, sans-serif', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>
          Search Result For :- {query}
        </h1>

        <div className="d-flex flex-wrap justify-content-around">
          {animeList.map((anime) => (
            <AnimeCard key={anime.id} anime={anime} />
          ))}
        </div>
        <div className='text-center'>
        <button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
        Previous Page
        </button>
        <span className='font-weight-bold' style={{color : 'orangered'}}>Page {currentPage} of {totalPages}</span>
        <button disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
          Next Page
        </button>
      </div>
        </div>
        <div style={{ margin: '20px', marginBottom: '20px', background: 'rgba(200, 200, 200, 0.3)', borderRadius: '50px', padding: '10px' }}>

      <h1 style={{ color: 'orangered', textAlign: 'center', fontFamily: 'Arial, sans-serif', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>
          Most Popular Anime List
        </h1>
        <div className="d-flex flex-wrap justify-content-around">
          {popanimeList.map((anime) => (
            <AnimeCard key={anime.id} anime={anime} />
          ))}
        </div>
      </div>

      
    </div>
  );
};

export default Result;
