import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'popper.js';
import Navbar from './Components/Common/Navbar';
import Alert from './Components/Common/Alert';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import SignIn from './pages/SignIn';
import EmailVerify from './pages/EmailVerify';
import SignUp from './pages/SignUp';
import Info from './Components/Movies/Info';
import View from './Components/Movies/View';
import Community from './pages/Community';
import Result from './Components/Movies/Result'
import ForgotPassword from './pages/ForgotPassword'; // Import ForgotPassword component
import ResetPassword from './pages/ResetPassword'; // Import ResetPassword component
import './App.css';
import Footer from "./Components/Common/Footer";

function App() {
  const [alert, setAlert] = useState(null);
  const server = process.env.REACT_APP_SERVER_URL
  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type
    });
    setTimeout(() => {
      setAlert(null);
    }, 3500);
  };

  return (
    <Router>
      <Navbar showAlert={showAlert} />
      <Alert alert={alert} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/signin" element={<SignIn showAlert={showAlert} server={server}/>} />
        <Route path="/signup" element={<SignUp showAlert={showAlert} server={server} />} />
        <Route path="/verify" element={<EmailVerify server={server}/>} />
        <Route path="/info" element={<Info />} />
        <Route path="/view" element={<View/>}/>
        <Route path="/result" element={<Result/>}/>
        <Route path="/community" element={<Community server={server}/>}/>
        <Route path="/forgotpassword" element={<ForgotPassword alert={showAlert} server={server}/>} />
        <Route path="/resetpassword/:token" element={<ResetPassword alert={showAlert} server={server}/>} /> 
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
