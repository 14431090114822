

import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import '../../styles/Info.css';
import { css } from '@emotion/react';
import AnimeCard from '../Common/AnimeCard';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Info = () => {
  const [query] = useSearchParams();
  const id = query.get('id');
  const [animeData, setAnimeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cat, setCat] = useState("sub");
  const [error, setError] = useState(null);
  


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await fetch(`https://anime-api-t143.onrender.com/anime/info?id=${id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setAnimeData(data);

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);


  if (loading) {
    return (
      <div className="spinner-container">
        <ScaleLoader color={'orangered'} css={override} size={150} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
    <div className="container mt-5">
      {animeData ? (
        <div className="card text-white" style={{ borderRadius: '20px', overflow: 'hidden', background: 'rgba(200, 200, 200, 0.2)' }}>
          <div className="row g-0">
            <div className="col-md-4">
              <img
                src={animeData.anime.info.poster}
                alt=""
                className="img-fluid w-100"
                style={{ borderRadius: '20px 0 0 20px', height: '100%' }}
              />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h2 className="card-title" style={{ color: 'orangered' }}>{animeData.anime.info.name}</h2>
                <Link to={`/view?id=${id}&category=${cat}`} className="btn btn-danger my-3">Watch Now</Link>
                <p className="card-text my-3" style={{ color: 'orange' }}>
                  {animeData.anime.info.description.length > 1000 ?
                    `${animeData.anime.info.description.slice(0, 1000)}...` :
                    animeData.anime.info.description
                  }
                </p>
                <div className="mb-3">
  <label htmlFor="categorySelect" className="form-label text-bold" style={{ color: 'orangered' }}>Select Category:</label>
  <div className="category-buttons">
    <button
      className={`btn ${cat === 'sub' ? 'btn-danger' : 'btn-secondary'}`}
      onClick={() => setCat('sub')}
    >
      Sub
    </button>
    <button
      className={`btn ${cat === 'dub' ? 'btn-danger' : 'btn-secondary'}`}
      onClick={() => setCat('dub')}
    >
      Dub
    </button>
  </div>
</div>

                <p className="card-text" style={{ color: 'white' }}>Sub: {animeData.anime.info.stats.episodes.sub}</p>
                <p className="card-text" style={{ color: 'white' }}>Dub: {animeData.anime.info.stats.episodes.dub}</p>
                <p className="card-text" style={{ color: 'white' }}>Type: {animeData.anime.info.stats.type}</p>
                <p className="card-text" style={{ color: 'white' }}>Duration: {animeData.anime.info.stats.duration}</p>
              </div>
            </div>
          </div>
        </div>
        
      ) : (
        <p>No data available.</p>
      )}
</div>
      <div style={{ margin: '20px', marginBottom: '20px', background: 'rgba(200, 200, 200, 0.3)', borderRadius: '50px', padding: '10px' }}>
            <h1 style={{ color: 'orangered', textAlign: 'center', fontFamily: 'Arial, sans-serif', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>Recommended Animes</h1>
            <div className="d-flex flex-wrap justify-content-around">
              {animeData.recommendedAnimes.map((anime) => (
                <AnimeCard key={anime.id} anime={anime} />
              ))}
            </div>
          </div>

          <div style={{ margin: '20px', marginBottom: '20px', background: 'rgba(200, 200, 200, 0.3)', borderRadius: '50px', padding: '10px' }}>
            <h1 style={{ color: 'orangered', textAlign: 'center', fontFamily: 'Arial, sans-serif', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>Related  Animes</h1>
            <div className="d-flex flex-wrap justify-content-around">
              {animeData.relatedAnimes.map((anime) => (
                <AnimeCard key={anime.id} anime={anime} />
              ))}
            </div>
          </div>
        


  



        

</>


  );
}

export default Info;
