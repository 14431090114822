import React, { useEffect, useRef, useState } from 'react';
import '../../styles/Navbar.css';
import { Link, NavLink } from 'react-router-dom';
import SearchInput from './Search';

const Navbar = (props) => {
  const [searchVisible, setSearchVisible] = useState(false);
  const [navVisible, setNavVisible] = useState(false);

  const toggleNav = () => {
    setNavVisible(!navVisible);
  };

  const closeNav = () => {
    setNavVisible(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    props.showAlert('Log Out Success', 'success');
  };

  const toggleSearch = () => {
    setSearchVisible(!searchVisible);
  };
// eslint-disable-next-line
  const closeSearch = () => {
    setSearchVisible(false);
  };

  const searchRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target) && searchVisible) {
        closeSearch();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchVisible, closeSearch]);

  return (
    <>
      <nav className="navbar-container">
        <Link to={'/'} style={{textDecoration:'none'}}>
        <div className="logo fw-bolder" style={{ color: 'orangered' }}>
          <img src={"https://i.pinimg.com/originals/c4/c0/6f/c4c06ffe199d64e8a9704ddcb1ff75b8.png"} style={{width:"45px",height:"40px",marginLeft:"10px"}} alt="..."/>
          ANI-FLOW
        </div></Link>
        <button className="burger-icon" onClick={toggleNav}>
          &#9776;
        </button>
        <div className={`nav-links ${navVisible ? 'active' : ''}`} onClick={closeNav}>
          <ul>
            <NavLink to="/">
              <i className="fas fa-home" style={{ color: 'orangered' }}></i> Home
            </NavLink>&nbsp;&nbsp;

            <NavLink to="/about">
              <i className="fas fa-info-circle" style={{ color: 'orangered' }}></i> About Us
            </NavLink>&nbsp;&nbsp;

            <NavLink to="/contact">
              <i className="fas fa-envelope" style={{ color: 'orangered' }}></i> Contact Us
            </NavLink>&nbsp;&nbsp;

            <NavLink to="/community">
              <i className="fas fa-comments" style={{ color: 'orangered' }}></i> Community
            </NavLink>&nbsp;&nbsp;
          </ul>
        </div>
        <button className="search-button" onClick={toggleSearch}>
          Search
        </button>
        <div className="auth-buttons">
          {localStorage.getItem('authToken') === '' || localStorage.getItem('authToken') === null ? (
            <>
              <NavLink to="/signin">
                <button>Sign In</button>
              </NavLink>
              <NavLink to="/signup">
                <button>Sign Up</button>
              </NavLink>
            </>
          ) : (
            <button onClick={handleLogout}>Log Out</button>
          )}
        </div>
      </nav>
      <div className="search-bar" style={{ display: searchVisible ? 'block' : 'none' }}>
        {searchVisible && <SearchInput closeSearch={closeSearch} />}
      </div>
    </>
  );
};

export default Navbar;
