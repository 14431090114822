
import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import AnimeInfo from './ViewInfo';
import '../../styles/View.css'; // Import your CSS file

const View = () => {
  const [query] = useSearchParams();
  const id = query.get('id');
  const cat = query.get('category');
  const [episodes, setEpisodes] = useState([]);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [episodeData, setEpisodeData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [displayedEpisodes, setDisplayedEpisodes] = useState([]);
  const playerRef = useRef(null);

  useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const episodesResponse = await fetch(`https://anime-api-t143.onrender.com/anime/episodes/${id}`);
        if (episodesResponse.ok) {
          const episodesData = await episodesResponse.json();
          setEpisodes(episodesData.episodes);
          setSelectedEpisode(episodesData.episodes[0]?.episodeId || null);
          // Update displayedEpisodes to show episodes 1-150 initially
          let last;
          if (episodesData.episodes.length > 150) {
            last = 150;
          } else {
            last = episodesData.episodes.length;
          }
          setDisplayedEpisodes(episodesData.episodes.slice(0, last));
        } else {
          console.error('Failed to fetch episodes:', episodesResponse.statusText);
        }
      } catch (error) {
        console.error('Error fetching episodes:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEpisodes();
  }, [id]);

  useEffect(() => {
    const fetchEpisodeSources = async () => {
      if (selectedEpisode) {
        try {
          const episodeSourcesResponse = await fetch(`https://anime-api-t143.onrender.com/anime/episode-srcs?id=${selectedEpisode}&category=${cat}`);
          if (episodeSourcesResponse.ok) {
            const episodeSourcesData = await episodeSourcesResponse.json();
            setEpisodeData(episodeSourcesData);
          } else {
            console.error('Failed to fetch episode sources:', episodeSourcesResponse);
          }
        } catch (error) {
          console.error('Error fetching episode sources:', error);
        }
      }
    };

    fetchEpisodeSources();
  }, [selectedEpisode, cat]);

  const handleEpisodeClick = (episodeId) => {
    setSelectedEpisode(episodeId);
    setIsPlaying(true);
  };

  const handleLoadedMetadata = () => {
    const subtitles = playerRef.current.getInternalPlayer()?.textTracks;

    if (subtitles && subtitles.length > 0) {
      console.log('Subtitles loaded:', subtitles);
    }
  };

  const handleOnReady = () => {
    setIsPlaying(false);
  };


  const renderEpisodesList = () => {
    return (
      <ul>
        {episodes.map((episode) => (
          <li key={episode.episodeId}>
            {episode.title} - Episode {episode.number}
            <button
              onClick={() => handleEpisodeClick(episode.episodeId)}
              className={episode.isFiller ? 'filler-button' : ''}
            >
              Play Episode
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const renderEpisodesMatrix = () => {
    const episodesCount = displayedEpisodes.length;
    const rows = Math.ceil(episodesCount / 5);

    return (
      <div style={{ height: '400px', overflowY: 'auto', width: '350px' }}>
        <table>
          <tbody>
            {[...Array(rows)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(5)].map((_, colIndex) => {
                  const episodeIndex = rowIndex * 5 + colIndex;
                  const episode = displayedEpisodes[episodeIndex];
                  return (
                    episode && (
                      <td key={colIndex}>
                        <button
                          onClick={() => handleEpisodeClick(episode.episodeId)}
                          className={episode.isFiller ? 'filler-button' : 'ep-button'}
                        >
                          {episode.number}
                        </button>
                      </td>
                    )
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderRangeOptions = () => {
    const totalEpisodes = episodes.length;
    const options = [];

    for (let i = 0; i < totalEpisodes; i += 150) {
      const start = i + 1;
      const end = Math.min(i + 150, totalEpisodes);
      options.push(
        <option key={i} value={`${start}-${end}`}>
          {`${start}-${end}`}
        </option>
      );
    }

    return options;
  };

  const handleRangeChange = (event) => {
    const [start, end] = event.target.value.split('-');
    setDisplayedEpisodes(episodes.slice(start - 1, end));
  };

  return (
    <div >
      <div className="view-container" style={{ margin: '20px',  background: 'rgba(200, 200, 200, 0.3)', borderRadius: '50px', padding: '10px' }}>
        <div className="table-container">
          {loading ? (
            <p>Loading...</p>
          ) : episodes.length <= 15 ? (
            renderEpisodesList()
          ) : (
            <>
              <select onChange={handleRangeChange} style={{ color: "white", backgroundColor: "black" , borderRadius:"6px" }}>
                {renderRangeOptions()}
              </select>

              {renderEpisodesMatrix()}
            </>
          )}
        </div>
        <div className="video-container" style={{ height: '450px' }}>
          {episodeData && episodeData.sources && episodeData.sources.length > 0 && (
            <ReactPlayer
              ref={playerRef}
              url={episodeData.sources[0]?.url || ''}
              controls
              style={{ zIndex: 1,marginLeft:"50%" }}             
              width="100%" 
              height="400px"
              onReady={handleOnReady}
              onLoadedMetadata={handleLoadedMetadata}
              config={{
                file: {
                  attributes: {
                    crossOrigin: 'true',
                  },
                  tracks: episodeData.tracks.map((subtitle, index) => ({
                    kind: 'subtitles',
                    src: subtitle.url,
                    srcLang: subtitle.lang,
                    label: subtitle.lang,
                    default: index === 0,
                  })),
                },
              }}
            />
          )}
          {isPlaying && <p>Loading...</p>}
        </div>
      </div>
      <div style={{margin:'20px'}}>
      <AnimeInfo animeId={id}/>
      </div>
    </div>
  );
};

export default View;
