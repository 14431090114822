// AnimeCard.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/AnimeCard.css';
import InfoCard from '../Movies/InfoCard';

function AnimeCard({ anime }) {
  const [showFullInfo, setShowFullInfo] = useState(false);
  const [isCardOnLeft, setIsCardOnLeft] = useState(false);

  const toggleInfo = () => {
    setShowFullInfo(!showFullInfo);
    // Check if the AnimeCard is on the left side of the screen
    const animeCard = document.querySelector('.anime-card');
    if (animeCard) {
      const animeCardRect = animeCard.getBoundingClientRect();
      setIsCardOnLeft(animeCardRect.left < window.innerWidth / 2);
    }
  };

  // Function to truncate the anime name to the first 5 words
  const truncateAnimeName = (name) => {
    const words = name.split(' ');
    const truncatedName = words.slice(0, 5).join(' ');
    return words.length > 5 ? `${truncatedName}...` : truncatedName;
  };

  return (
    <div className={`anime-card-container`} onMouseEnter={toggleInfo} onMouseLeave={toggleInfo}>
      <div className={`card mb-4 anime-card ${showFullInfo ? 'hovered' : ''}`} style={{ position: 'relative', width: '18rem', marginBottom: '0px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '10px', transition: 'transform 0.3s' }}>
        <Link to={`/info?id=${anime.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <img className="card-img-top" src={anime.poster} alt={anime.name} style={{ height: '400px', objectFit: 'cover', borderRadius: '10px 10px 0 0' }} />
        
        <div className="card-body" style={{ maxHeight: '15rem', overflowY: 'auto' }}>
          {anime.rank && (
            <p className="card-text" style={{ fontSize: '1rem', fontWeight: 'bold', color: '#007BFF' }}>
              Rank: {anime.rank}
            </p>
          )}
          <h5 className="card-title" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'orangered' }}>{truncateAnimeName(anime.name)}</h5>
          {/* Other card content */}
        </div>
        </Link>
      </div>
      {showFullInfo && <InfoCard anime={anime} className={isCardOnLeft ? 'left' : 'right'} />}
    </div>
  );
}

export default AnimeCard;
