import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_6yicwsv', 'template_a4jbegm', form.current, 'utyA7GS4BE6cLnpMX')
            .then((result) => {
                console.log(result.text);
                console.log("messagesent");
                alert("Message sent successfully!");
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <StyledPage>
            <StyledContactForm>
               
                <StyledMessage>Please Leave a Message</StyledMessage>
                <form ref={form} onSubmit={sendEmail}>
                    <label>Name</label>
                    <input type="text" name="user_name" />
                    <label>Email</label>
                    <input type="email" name="user_email" />
                    <label>Message</label>
                    <textarea name="message" />
                    <input type="submit" value="Send" />
                </form>
            </StyledContactForm>
        </StyledPage>
    );
};

export default Contact;

// Styles
const StyledPage = styled.div`
    background-color: black; /* Set the background color of the entire page to black */
    min-height: 100vh; /* Ensure the page takes up at least the full height of the viewport */
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledContactForm = styled.div`
    width: 400px;
  
    form {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        font-size: 16px;
        input,
        textarea {
            width: 100%;
            height: 35px;
            padding: 7px;
            outline: none;
            border-radius: 5px;
            border: 1px solid rgb(220, 220, 220);
            color: white;
            background-color: black;
            &:focus {
                border: 2px solid rgba(0, 206, 158, 1);
            }
        }
        textarea {
            max-width: 100%;
            min-width: 100%;
            max-height: 100px;
            min-height: 100px;
        }
        label {
            margin-top: 1rem;
            color: white;
        }
        input[type="submit"] {
            margin-top: 2rem;
            cursor: pointer;
            background: orangered; /* Change background color to dark orange */
            color: black; /* Change text color to black */
            border: none;
        }
    }
`;

const StyledMessage = styled.h2`
    color: orangered; /* Set the color to dark orange */
    margin-bottom: 1rem;
`;
