import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import AnimeCard from '../Components/Common/AnimeCard';
import { ScaleLoader } from 'react-spinners';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function Home() {
  const [spotlightAnimes, setSpotlightAnimes] = useState([]);
  const [genres, setGenres] = useState([]);
  const [trendingAnimes, setTrendingAnimes] = useState([]);
  const [latestEpisodeAnimes, setLatestEpisodeAnimes] = useState([]);
  const [topUpcomingAnimes, setTopUpcomingAnimes] = useState([]);
  const [top10AnimesToday, setTop10AnimesToday] = useState([]);
  const [topAiringAnimes, setTopAiringAnimes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('https://anime-api-t143.onrender.com/anime/home');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setSpotlightAnimes(data.spotlightAnimes.slice(0, 13));
        setGenres(data.genres);
        setTrendingAnimes(data.trendingAnimes);
        setLatestEpisodeAnimes(data.latestEpisodeAnimes);
        setTopUpcomingAnimes(data.topUpcomingAnimes);
        setTop10AnimesToday(data.top10Animes.today);
        setTopAiringAnimes(data.topAiringAnimes);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="spinner-container">
        <ScaleLoader color={'orangered'} css={override} size={150} />
      </div>
    );
  }


  // Define a set of colors
  const colors = [
    '#FF5733', '#DAF7A6', '#FFC300', '#C70039', '#900C3F', '#FF5733', '#DAF7A6', '#FFC300',
    '#C70039', '#900C3F', '#FF5733', '#DAF7A6', '#FFC300', '#C70039', '#900C3F', '#FF5733',
    '#DAF7A6', '#FFC300', '#C70039', '#900C3F', '#FF5733', '#DAF7A6', '#FFC300', '#C70039',
    '#900C3F', '#FF5733', '#DAF7A6', '#FFC300', '#C70039', '#900C3F', '#FF5733', '#DAF7A6',
    '#FFC300', '#C70039', '#900C3F', '#FF5733', '#DAF7A6', '#FFC300', '#C70039', '#900C3F',
    '#FF5733', '#DAF7A6', '#FFC300', '#C70039', '#900C3F'
  ];

  return (
    <div>
      <Carousel style={{ maxHeight: '800px', overflow: 'hidden' }}>
        {spotlightAnimes.map((anime) => (
          <Carousel.Item key={anime.id}>
            <Link to={`/info?id=${anime.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ position: 'relative', height: '500px', overflow: 'hidden' }}>
                <span
                  style={{
                    position: 'absolute',
                    top: '20px',
                    left: '10px',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    padding: '10px',
                    borderRadius: '10px',
                    fontWeight: 'bold',
                    fontSize: '20px', // Adjust the font size as needed
                    zIndex: 1, // Set z-index to bring it to the front
                  }}
                >
                  #{anime.rank} Spotlight
                </span>
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                  <img
                    className="d-block w-100"
                    src={anime.poster}
                    alt={anime.name}
                    style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                  />
                  <Carousel.Caption style={{ position: 'absolute', width: '80%', bottom: '0',left:'50%', transform: 'translateX(-50%)', textAlign: 'center', color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.3)', padding: '20px', borderRadius: '50px' }}>
                    <h3 style={{ textAlign: 'center', marginBottom: '10px', color: '#FFD700' }}>{anime.name}</h3>
                    <p style={{ fontSize: '14px', fontWeight: 'bold', color: '#00FFFF' }}>
                      SUB: {anime.episodes.sub !== null ? anime.episodes.sub : 0} / DUB: {anime.episodes.dub !== null ? anime.episodes.dub : 0}
                    </p>
                    <p style={{ fontSize: '16px', color: 'white' }}>
                      {anime.description.split(' ').length > 20
                        ? `${anime.description.split(' ').slice(0, 40).join(' ')}...`
                        : anime.description}
                    </p>
                  </Carousel.Caption>
                </div>
              </div>
            </Link>
          </Carousel.Item>
        ))}
      </Carousel>

      <div style={{ margin: '20px', marginBottom: '20px', background: 'rgba(200, 200, 200, 0.3)', borderRadius: '50px', padding: '10px' }}>
        <h1 style={{ color: 'orangered', textAlign: 'center', fontFamily: 'Arial, sans-serif', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>
          Trending Animes
        </h1>

        <div className="d-flex flex-wrap justify-content-around">
          {trendingAnimes.map((anime) => (
            <AnimeCard key={anime.id} anime={anime} />
          ))}
        </div>
      </div>

      <div style={{ margin: '20px', marginBottom: '20px', background: 'rgba(200, 200, 200, 0.3)', borderRadius: '50px', padding: '10px' }}>
        <h1 style={{ color: 'orangered', textAlign: 'center', fontFamily: 'Arial, sans-serif', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>
          Latest Episode Animes
        </h1>

        <div className="d-flex flex-wrap justify-content-around">
          {latestEpisodeAnimes.map((anime, index) => (
            <AnimeCard key={anime.id} anime={anime} />
          ))}
        </div>
      </div>
      <div style={{ margin: '20px', marginBottom: '20px', background: 'rgba(200, 200, 200, 0.3)', borderRadius: '50px', padding: '10px' }}>
        <h1 style={{ color: 'orangered', textAlign: 'center', fontFamily: 'Arial, sans-serif', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>
          Top Upcoming Animes
        </h1>

        <div className="d-flex flex-wrap justify-content-around">
        {topUpcomingAnimes.map((anime, index) => (
          <AnimeCard key={anime.id} anime={anime} />
        ))}
      </div>
      </div>
      <div style={{ margin: '20px', marginBottom: '20px', background: 'rgba(200, 200, 200, 0.3)', borderRadius: '50px', padding: '10px' }}>
        <h1 style={{ color: 'orangered', textAlign: 'center', fontFamily: 'Arial, sans-serif', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>
          Top Airing Anime
        </h1>

        <div className="d-flex flex-wrap justify-content-around">
        {topAiringAnimes.map((anime, index) => (
          <AnimeCard key={anime.id} anime={anime} />
        ))}
      </div>
      </div>
      <div style={{ margin: '20px', marginBottom: '20px', background: 'rgba(200, 200, 200, 0.3)', borderRadius: '50px', padding: '10px' }}>
        <h1 style={{ color: 'orangered', textAlign: 'center', fontFamily: 'Arial, sans-serif', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>
          Top 10 Anime Today
        </h1>

        <div className="d-flex flex-wrap justify-content-around">
        {top10AnimesToday.map((anime, index) => (
          <AnimeCard key={anime.id} anime={anime} />
        ))}
      </div>
          </div>
      <h1>Genres</h1>
      <div className="d-flex flex-wrap justify-content-around">
        {genres.map((genre, index) => (
          <div key={index} className="col-sm-3">
            <div className="p-3" style={{ backgroundColor: colors[index % colors.length], color: 'white' }}>{genre}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;
