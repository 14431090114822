import React, { useEffect } from 'react';
import '../../styles/Alert.css';

const Alert = (props) => {
  const capitalize = (word) => {
    if (!word) {
      return '';
    }

    if (word === 'danger') {
      word = 'Error';
    }

    const lower = word.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  };

  useEffect(() => {
    let timeout;
    if (props.alert) {
      // After 3 seconds, slide the alert to the right
      timeout = setTimeout(() => {
        const alertElement = document.getElementById('custom-alert');
        if (alertElement) {
          alertElement.style.animation = 'slideRight 0.5s ease-out';
        }
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [props.alert]);

  if (props.alert !== null) {
    const alertClass = `alert alert-${props.alert.type}`;
    const animationClass = `slideLeft 0.5s ease-out`;

    return (
      <div
        id="custom-alert"
        style={{
          height: '50px',
          position: 'fixed',
          top: '60px',
          right: '20px',
          zIndex: 1000,
          animation: animationClass,
        }}
      >
        {props.alert && (
          <div
            className={alertClass}
            role="alert"
            style={{
              backgroundColor: props.alert.type === 'success' ? '#28a745' : props.alert.type === 'info' ? '#007bff' : '#dc3545',
              color: '#fff',
              padding: '10px',
              borderRadius: '4px',
              boxShadow: '0 0 10px rgba(0,0,0,0.1)',
            }}
          >
            <strong>{capitalize(props.alert.type)}</strong>: {props.alert.msg}
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default Alert;
