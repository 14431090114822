import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import '../styles/SignIn.css';
import { css } from '@emotion/react';


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const SignIn = (props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false); // Added loading state

  const handleForgotPassword = () => {
    navigate('/forgotpassword');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = formData;

    try {
      setLoading(true); // Set loading to true when starting the request

      const response = await fetch(props.server + '/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      console.log('API Response:', data);
      if (data.success) {
        props.showAlert('Log In Success', 'success');
        localStorage.setItem('authToken', data.authtoken);
        navigate('/');
      } else if (!data.success) {
        const err = (data.errors && data.errors.length > 0 && data.errors[0].msg) || data.message;
        props.showAlert(err, 'danger');
      }
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setLoading(false); // Set loading to false when the request is completed
    }
  };

  if (loading) {
    return (
      <div className="spinner-container">
        <ScaleLoader color={'orangered'} css={override} size={150} />
      </div>
    );
  }

  return (
    <div className="sign-in-container">
      <form className="sign-in-form" onSubmit={handleSubmit}>
        <h2>Sign In</h2>
        <label htmlFor="email">Email</label>
        <input
          type="text"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />

        <button type="submit">Sign In</button>

      
        <p onClick={handleForgotPassword}>Forgot Password?</p>
      </form>
    </div>
  );
};

export default SignIn;
