import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Community.css';
const Community = (props) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState({ user: '', text: '' });
  const [username, setUsername] = useState('');
  const [replyTo, setReplyTo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the username by hitting the /getuser API
    const fetchUsername = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
          // If not present, show an error and redirect to the login page
          alert('You need to log in to send messages.');
          navigate('/signin');
          return;
        }

        const response = await fetch(props.server+'/api/auth/getuser', {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/json',
            'auth-token': authToken, // Include the authToken in headers if needed
          },
        });

        if (!response.ok) {
          // Handle the case where the request fails
          console.error('Failed to fetch user details:', response.statusText);
          return;
        }

        const userData = await response.json();
        setUsername(userData.name);  // Assuming the user object has a 'username' property
      } catch (error) {
        console.error('Error fetching user details:', error.message);
      }
    };

    fetchUsername();

    // Fetch all messages on component mount
    fetch(props.server+'/api/msg/')
      .then(response => response.json())
      .then(data => setMessages(data))
      .catch(error => console.error('Error fetching messages:', error));

    // Fetch messages periodically (every 5 seconds in this example)
    const intervalId = setInterval(() => {
      fetch(props.server+'/api/msg/')
        .then(response => response.json())
        .then(data => setMessages(data))
        .catch(error => console.error('Error fetching messages:', error));
    }, 500); // Adjust the interval as needed

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [navigate,props.server]);

  const handleNewMessageChange = (e) => {
    setNewMessage({ ...newMessage, [e.target.name]: e.target.value });
  };

  const handleSendMessage = () => {
    // Check if authToken is present in localStorage
    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
      // If not present, show an error and redirect to the login page
      alert('You need to log in to send messages.');
      navigate('/signin');
      return;
    }

    // Send a new message to the server
    const url = replyTo
      ? props.server+`/api/msg/${replyTo}/replies`
      : props.server+'/api/msg/';

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`, // Include the authToken in headers if needed
      },
      body: JSON.stringify({ ...newMessage, user: username }),
    })
      .then(response => response.json())
      .then(data => {
        setMessages([...messages, data]);
        setNewMessage({ user: '', text: '' });
        setReplyTo(null); // Reset replyTo after sending a message
      })
      .catch(error => console.error('Error sending message:', error));
  };

  const handleReply = (messageId) => {
    setReplyTo(messageId);
  };

  return (
    <div className="community-container">
      <h2 className="community-heading">Community Chat</h2>
      <p className="welcome-message">Welcome, {username}!</p>
      <div className="messages-container">
        {messages.slice().reverse().map(message => (
          <div key={message._id} className="message-card">
            <p className="message-text">{message.user}: {message.text}</p>
            <button className="reply-button" onClick={() => handleReply(message._id)}>Reply</button>
            {message.replies && message.replies.length > 0 && (
              <ul className="reply-list">
                {message.replies.map(reply => (
                  <li key={reply._id} className="reply-item">{reply.user}: {reply.text}</li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
      <div className="new-message-container">
        <textarea
          className="message-input"
          name="text"
          placeholder="Type your message"
          value={newMessage.text}
          onChange={handleNewMessageChange}
        />
        {replyTo && <p className="reply-info">Replying to: {messages.find(message => message._id === replyTo)?.text}</p>}
        <button className="send-button" onClick={handleSendMessage}>{replyTo ? 'Reply' : 'Send Message'}</button>
      </div>
    </div>
  );
};

export default Community;