// VerificationPage.js

import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

const VerificationPage = (props) => {
  const [query] = useSearchParams();
  const token = query.get('token');
  const [verificationStatus, setVerificationStatus] = useState(null);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        if (!token) {
          // Token is not present, handle accordingly (redirect, show error, etc.)
          setVerificationStatus('error');
          return;
        }

        const response = await fetch(props.server+`/api/auth/verify?token=${token}`);
        const data = await response.json();

        if (data.success) {
          setVerificationStatus('success');
        } else {
          setVerificationStatus('error');
        }

        console.log("verify done");
      } catch (error) {
        console.error('Error during email verification:', error);
        setVerificationStatus('error');
      }
    };

    verifyEmail();
  }, [token,props.server]);

  return (
    <div>
      {verificationStatus === 'success' ? (
        <>
          <p>Email verified successfully! You can now login. <Link to="/signin">SignIn</Link></p>
        </>
      ) : verificationStatus === 'error' ? (
        <p>Verification failed. Please contact support.</p>
      ) : (
        <p>Verifying...</p>
      )}
    </div>
  );
};

export default VerificationPage;
