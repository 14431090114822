import React, { useState, useEffect } from 'react';
import '../../styles/AnimeInfo.css'
import AnimeCard from '../Common/AnimeCard';
const AnimeInfo = ({ animeId }) => {
  const [animeInfo, setAnimeInfo] = useState(null);
  const [animeTrending, setAnimeTrending] = useState([]);
  const [LatestEp, setLatestEp] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAnimeInfo = async () => {
      try {
        const response = await fetch(`https://anime-api-t143.onrender.com/anime/info?id=${animeId}`);
        const responseHome = await fetch('https://anime-api-t143.onrender.com/anime/home');
        if (response.ok && responseHome.ok) {
          const data = await response.json();
          const animehome = await responseHome.json()
          setAnimeInfo(data.anime.info);
          setAnimeTrending(animehome.trendingAnimes)
          console.log(animehome.latestEpisodeAnimes);
          setLatestEp(animehome.latestEpisodeAnimes)
        } else {
          console.error('Failed to fetch anime info:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching anime info:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAnimeInfo();
  }, [animeId]);

  return (
    <>
    <div className="anime-info-container">
      {loading ? (
        <p className="loading">Loading anime info...</p>
      ) : animeInfo ? (
        <>
          <img src={animeInfo.poster} alt={`${animeInfo.name} Poster`} />
          <div className="anime-info-details">
            <h1>{animeInfo.name}</h1>
            <p>{animeInfo.description}</p>
            <div>
              <p>Rating: {animeInfo.stats.rating}</p>
              <p>Quality: {animeInfo.stats.quality}</p>
              <p>Episodes (Sub): {animeInfo.stats.episodes.sub}</p>
              <p>Episodes (Dub): {animeInfo.stats.episodes.dub}</p>
              <p>Type: {animeInfo.stats.type}</p>
              <p>Duration: {animeInfo.stats.duration}</p>
            </div>
          </div>
        </>
      ) : (
        <p className="error">Failed to fetch anime info.</p>
      )}
    </div>
    
    <div style={{ margin: '20px', marginBottom: '20px', background: 'rgba(200, 200, 200, 0.3)', borderRadius: '50px', padding: '10px' }}>
        <h1 style={{ color: 'orangered', textAlign: 'center', fontFamily: 'Arial, sans-serif', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>
          Trending Animes
        </h1>

        <div className="d-flex flex-wrap justify-content-around">
          {animeTrending.map((anime) => (
            <AnimeCard key={anime.id} anime={anime} />
          ))}
        </div>
      </div>
    <div style={{ margin: '20px', marginBottom: '20px', background: 'rgba(200, 200, 200, 0.3)', borderRadius: '50px', padding: '10px' }}>
        <h1 style={{ color: 'orangered', textAlign: 'center', fontFamily: 'Arial, sans-serif', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>
          Latest Episode Animes
        </h1>

        <div className="d-flex flex-wrap justify-content-around">
          {LatestEp.map((anime) => (
            <AnimeCard key={anime.id} anime={anime} />
          ))}
        </div>
      </div>

    </>
  );
};

export default AnimeInfo;