import React from 'react';
import abt1 from  '../images/abt1.png';
import abt2 from '../images/abt2.png';




const AboutUsPage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>About Us</h1>
      <div style={styles.content}>
        <div style={styles.textContainer}>
          <p style={styles.text}>We are passionate anime enthusiasts dedicated to bringing you the best content and information about your favorite anime series.</p>
          <p style={styles.text}>Our team works tirelessly to curate the latest news, reviews, and recommendations so you can stay up-to-date with the exciting world of anime.</p>
          <p style={styles.text}>Join us on our journey to explore the vast universe of anime and discover new adventures together!</p>
        </div>
        <div style={styles.imageContainer}>
        <img src={abt1} alt="Anime 1" style={styles.image} />

        </div>
      </div>
      <div style={styles.content}>
        <div style={styles.imageContainer}>
        <img src={abt2} alt="Anime 2" style={styles.image} />

        </div>
        <div style={styles.textContainer}>
          <p style={styles.text}>We are a diverse team of anime enthusiasts with a shared passion for bringing you the latest news, reviews, and insights into the world of anime.</p>
          <p style={styles.text}>From uncovering hidden gems to discussing popular series, we aim to provide a comprehensive view of the anime landscape.</p>
          <p style={styles.text}>Join us as we embark on this journey to explore the boundless creativity and imagination of anime!</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '50px 20px',
    textAlign: 'center',
  },
  heading: {
    fontSize: '36px',
    fontWeight: 'bold',
    marginBottom: '50px',
    color: 'orangered',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '50px',
  },
  textContainer: {
    flex: '1',
    backgroundColor: 'black',
    padding: '20px',
    borderRadius: '20px',
  },
  imageContainer: {
    flex: '1',
    textAlign: 'center',
  },
  text: {
    color: 'orangered',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '300px',
    borderRadius: '8px',
  },
};

export default AboutUsPage;